<style lang="scss" scoped>
    #insurers-container {
        max-width: 600px;
        margin-top: 100px;
    }
</style>

<template>
    <v-container id="insurers-container">
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        Escolha uma companhia
                    </v-card-title>

                    <v-card-text v-if="error" class="text-center">
                        <v-icon size="100" class="mt-10 mb-2">mdi-connection</v-icon>
                        <div>
                            Não foi possível carregar companhias
                        </div>
                        <v-btn @click="getInsurers()" color="primary" class="mt-5">
                            Recarregar
                        </v-btn>
                    </v-card-text>
                    
                    <v-card-text v-else-if="loading" class="text-center">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                            class="my-10"
                        ></v-progress-circular>
                    </v-card-text>

                    <v-list v-else>
                        <v-list-item 
                            v-for="insurer in insurers" 
                            :key="insurer.id" 
                            link
                            :to="{
                                name:'hcm_universe.portal_companhias.users',
                                params: {
                                    id: insurer.id
                                }
                            }"
                            exact
                            :disabled="insurer.domain == null"
                        >
                            <v-list-item-avatar>
                                <v-icon>mdi-domain</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ insurer.name }}
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-icon>
                                    mdi-chevron-right
                                </v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { index } from "@/services/PortalCompanhias/insurers";

export default {
    data() {
        return {
            insurers: [],
            loading: true,
            error: false
        };
    },

    methods: {
        async getInsurers() {
            this.error = false;
            this.loading = true;

            try {
                const response = await index();

                this.insurers = response.data;
            } catch(error) {
                this.error = true;
            } finally {
                this.loading = false;
            }
        }
    },

    created() {
        this.getInsurers();
    }
}
</script>